<template>
  <div class="order-examine-popup">
    <el-dialog title="费用核销"
               :close-on-click-modal="false"
               :visible.sync="visible"
               width="30%" center>
      <el-form :model="dataForm" ref="dataForm" size="small" label-width="100px">
        <el-row :gutter="50">
          <el-col :span="21">
            <el-form-item :label="this.type === 1 ? '收款人' : '付款人'" prop="writeName" :rules="[{ required: true, message: '此项不能为空', trigger: 'blur' }]">
              <el-input v-model="dataForm.writeName"  clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="21">
            <el-form-item :label="this.type === 1 ? '收款日期' : '付款日期'" prop="writeDate" :rules="[{ required: true, message: '请选择日期', trigger: 'change' }]">
              <el-date-picker
                v-model="dataForm.writeDate"
                align="right"
                type="date"
                style="width: 100%"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button type="primary" @click="visible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      type: null,
      dataForm: {
        ids: null,
        writeStatus: null,
        writeName: null,
        writeDate: null
      }
    }
  },
  methods: {
    init (type, writeStatus, ids) {
      this.visible = true
      this.type = type
      this.dataForm.ids = ids
      this.dataForm.writeStatus = writeStatus
    },
    confirm () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/cardWarn/CardWarnBatchWrite`),
            method: 'POST',
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('系统错误')
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
